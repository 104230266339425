var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "CompaniesListView" },
    [
      _c(
        "div",
        { staticClass: "d-flex no-gutters justify-content-between mb-3" },
        [
          _c("h1", [_vm._v(" Companies ")]),
          _c(
            "div",
            { staticClass: "d-flex justify-content-end" },
            [
              _c(
                "ui-button",
                {
                  staticClass: "emobg-font-small",
                  on: {
                    clickbutton: function ($event) {
                      _vm.isCreateCompanyModalVisible = true
                    },
                  },
                },
                [_vm._v(" Create company ")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("MuiAlgoliaList", {
        ref: "companies_list",
        attrs: {
          facets: _vm.companyListfacets,
          filters: _vm.getOperatorFilter({ attribute: "cs_operator_id" }),
          "table-config": _vm.contentCells,
          "export-columns": _vm.contentCells,
          "query-parameters": {
            attributesToRetrieve: _vm.attributesToRetrieve,
            restrictSearchableAttributes: _vm.restrictSearchableAttributes,
          },
          index: _vm.ALGOLIA_INDEXES.companies,
          "empty-message": _vm.getNoResultsLabel("companies"),
          "is-export-enabled": "",
        },
      }),
      _vm.isCreateCompanyModalVisible
        ? _c("CreateCompanyModal", {
            attrs: { "on-success": _vm.onCreateCompanySuccess },
            on: {
              closeModal: function ($event) {
                _vm.isCreateCompanyModalVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }