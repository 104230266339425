<script>
export default {
  name: 'CompaniesView',
};
</script>

<template>
  <div class="CompaniesView flex-fill d-flex flex-column">
    <RouterView />
  </div>
</template>
