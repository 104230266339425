<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import has from 'lodash/has';
import map from 'lodash/map';
import defaultsDeep from 'lodash/defaultsDeep';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { FRENCH_COMPANY_VALIDATION_PATTERN, sentenceCase } from '@emobg/web-utils';
import { EXCEPTION_KEYS } from '@domains/CRM/Companies/const/exceptionKeys';
import { COMPANY_SCOPES } from '@domains/CRM/Companies/store/CompanyModule';
import { BUTTON_TYPES } from '@emobg/vue-base';
import {
  FISCAL_CODE_VALIDATIONS,
  GOOGLE_ADDRESS_VALIDATIONS,
  PHONE_VALIDATIONS,
} from '@/utils';
import {
  GenericModalComponent,
  GoogleLocationSelect,
  PhoneInputComponent,
  StoreNotificationComponent,
} from '@/components';
import { Cancel } from '@/constants/modalButtons.const';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';

export default {
  name: 'CreateCompany',
  directives: {
    Validate,
  },
  components: {
    PhoneInputComponent,
    GoogleLocationSelect,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiValidationWrapper,
    MuiInputText,
    StoreNotificationComponent,
  },
  props: {
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      fiscalNumberAlreadyUsed: null,
      hasTariffForCityException: false,
      inputs: {
        commercialName: null,
        email: null,
        phoneNumber: null,
        addressStreet: null,
        addressStreetObject: null,
        addressStreet2: null,
        cityUuid: null,
        localeCode: null,
        fiscalName: null,
        fiscalCode: null,
        fiscalNumber: null,
        siretNumber: null,
      },
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
      'activeOperator',
      'isFrenchOperator',
    ]),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      currentCountryCode: state => get(state, 'operators.countryCode'),
    }),
    ...mapState(DOMAINS_MODEL.crm.company, {
      companyCreateStatus: state => state.companyCreate.STATUS,
      companyCreateError: state => state.companyCreate.error,
    }),

    hasSiretNumber() {
      const company = get(this, 'company');
      return has(company, 'siretNumber');
    },
    modalButtons() {
      return [
        defaultsDeep(
          {
            listeners: {
              click: () => this.$emit('closeModal'),
            },
            attributes: {
              'data-test-id': 'agent_form_modal-cancel-button',
            },
          },
          Cancel,
        ),
        {
          attributes: {
            form: 'CompanyCreateForm',
            type: BUTTON_TYPES.submit,
            loading: this.companyCreateStatus.LOADING,
            'data-test-id': 'company_create_form_modal-confirm-button',
          },
          text: 'Create',
        },
      ];
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.localesIndex = ALGOLIA_INDEXES.locales;
    this.PHONE_VALIDATIONS = PHONE_VALIDATIONS;
    this.FISCAL_CODE_VALIDATIONS = FISCAL_CODE_VALIDATIONS;
    this.GOOGLE_ADDRESS_VALIDATIONS = GOOGLE_ADDRESS_VALIDATIONS;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.COMPANY_SCOPES = COMPANY_SCOPES;
    this.FRENCH_COMPANY_VALIDATION_PATTERN = FRENCH_COMPANY_VALIDATION_PATTERN;
    this.inputs.operatorUuid = this.activeOperator.uuid;
    this.originalInputs = cloneDeep(this.inputs);
  },
  methods: {
    ...mapActions(DOMAINS_MODEL.crm.company, ['postCompanyCreate']),
    sentenceCase,
    get,
    isEqual,
    map,
    async saveCompany() {
      await this.postCompanyCreate({ company: this.inputs });

      if (!this.companyCreateStatus.ERROR) {
        this.$emit('closeModal');
        this.$notify({
          message: 'Company successfully <span class="emobg-font-weight-semibold">created</span>',
          textAction: '',
        });

        this.onSuccess();
      } else {
        this.hasTariffForCityException = get(this.companyCreateError, 'key') === EXCEPTION_KEYS.invalidTariff;
        this.fiscalNumberAlreadyUsed = get(this.companyCreateError, 'key') === EXCEPTION_KEYS.duplicatedFiscalNumber
          ? get(this.companyCreateError, 'custom_data.fiscal_number') : null;
        this.$refs.CompanyCreateForm.validateAll();
      }
    },
    catchNonExistingTariffCity() {
      return {
        isValid: !this.hasTariffForCityException,
        message: this.hasTariffForCityException ? 'There is no existing tariff for this city.' : '',
      };
    },
    catchVATNumberError(fiscalNumber) {
      return {
        isValid: fiscalNumber !== this.fiscalNumberAlreadyUsed,
        message: fiscalNumber === this.fiscalNumberAlreadyUsed ? 'This VAT number already exists.' : '',
      };
    },
    checkValidAddress(place) {
      return GOOGLE_ADDRESS_VALIDATIONS.hasRequiredComponents(this.inputs.addressStreetObject ?? place);
    },
  },
};
</script>
<template>
  <GenericModalComponent
    :header="{ isClosable: true }"
    :buttons="modalButtons"
    title="Create company"
    class="CreateCompany"
    data-test-id="create_company-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.crm.company"
        :scope="COMPANY_SCOPES.companyCreate"
        element="company"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <MuiValidationWrapper
        id="CompanyCreateForm"
        ref="CompanyCreateForm"
        @areAllValid="valid => isFormValid = valid"
        @submit="saveCompany"
      >
        <h3 class="mb-4">
          Company Profile
        </h3>
        <div class="mb-4">
          <MuiInputText
            v-model="inputs.commercialName"
            v-validate="{
              isRequired: true,
            }"
            label="Company name*"
            class="w-100"
            placeholder="Enter name"
            data-test-id="commercial_name"
            name="commercial_name"
          />
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.email"
              v-validate="{
                isRequired: true,
                isEmail: true,
              }"
              label="Email*"
              class="w-100"
              placeholder="Enter email"
              data-test-id="email"
              name="email"
            />
          </div>
          <div class="col-6 mb-4">
            <PhoneInputComponent
              v-model="inputs.phoneNumber"
              v-validate="{
                isRequired: true,
                isValidPhone: PHONE_VALIDATIONS.isValidPhone,
              }"
              label="Phone number*"
              class="w-100"
              placeholder="Enter phone number"
              name="phoneNumber"
              data-test-id="phone_number"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <GoogleLocationSelect
              v-model="inputs.addressStreet"
              v-validate.update:place="{
                isRequired: true,
                isValidAddress: checkValidAddress,
              }"
              placeholder="Enter address"
              label="Address*"
              class="w-100"
              name="addressStreet"
              data-test-id="address_street"
              @update:place="place => inputs.addressStreetObject = place"
              @create:place="place => inputs.addressStreetObject = place"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiInputText
              v-model="inputs.addressStreet2"
              label="Floor, door, apartment..."
              class="w-100"
              placeholder="Enter address"
              name="addressStreet2"
              data-test-id="address_street2"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.cityUuid"
              v-validate="{
                isRequired: true,
                catchNonExistingTariffCity,
              }"
              :filters="getOperatorFilter({ index: ALGOLIA_INDEXES.cities })"
              :title="city => city.name"
              :index="ALGOLIA_INDEXES.cities"
              label="City*"
              placeholder="Select"
              class="w-100"
              path-value="uuid"
              name="city"
              @change="() => hasTariffForCityException = false"
            />
          </div>
          <div class="col-6 mb-4">
            <MuiAlgoliaSelect
              v-model="inputs.localeCode"
              v-validate="{
                isRequired: true,
              }"
              :title="locale => `${locale.name} (${locale.code})`"
              :index="localesIndex"
              filters="active: true"
              label="Language*"
              path-value="code"
              class="w-100"
              placeholder="Select"
              name="localeCode"
              data-test-id="locale-select"
            />
          </div>
        </div>
        <h3 class="mb-4">
          Fiscal Information
        </h3>
        <div class="row">
          <div class="col-6">
            <MuiInputText
              v-model="inputs.fiscalName"
              v-validate="{ isRequired: true }"
              label="Company legal name*"
              data-test-id="fiscal_name"
              name="fiscalName"
              placeholder="Enter name"
            />
          </div>
          <div class="col-6">
            <MuiInputText
              v-model="inputs.fiscalNumber"
              v-validate="{
                isRequired: true,
                catchVATNumberError,
              }"
              label="VAT number*"
              data-test-id="fiscal_number"
              name="fiscalNumber"
              placeholder="Enter code"
            />
          </div>
        </div>
        <template v-if="get(activeOperator, 'is_italian')">
          <MuiInputText
            v-model="inputs.fiscalCode"
            v-validate="{
              isRequired: true,
              isValidFiscalCode: FISCAL_CODE_VALIDATIONS.isValidCompanyFiscalCode,
            }"
            label="Fiscal code*"
            class="mt-4"
            data-test-id="fiscal_code"
            name="fiscalCode"
            placeholder="Enter code"
          />
          <label class="d-block emobg-font-x-small emobg-color-ink-light mt-1">
            Fiscal code is a unique combination of 16 numbers and letters or 11 numbers
          </label>
        </template>
        <template v-if="isFrenchOperator">
          <MuiInputText
            v-model="inputs.siretNumber"
            v-validate="{
              isRequired: true,
              isPattern: {
                pattern: FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber,
                message: 'Invalid SIRET number format',
              },
            }"
            label="SIRET number*"
            class="mt-4"
            data-test-id="siret_number"
            name="siretNumber"
            placeholder="Enter number"
          />
          <label class="d-block emobg-caption-1 emobg-color-ink mt-1 px-1">
            SIRET number is a unique combination of 14 numbers
          </label>
        </template>
      </MuiValidationWrapper>
    </template>
  </GenericModalComponent>
</template>
