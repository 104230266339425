import { sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
import { PermissionLink } from '@/components';
import { EmailTemplate, PhoneTemplate } from '@/templates';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';
import { COMPANY_STATUS_COLORS } from './company.const';

export const attributesToRetrieve = [
  'id',
  'uuid',
  'status',
  'city',
  'has_dedicated_fleet',
  'commercial_name',
  'email',
  'phone_1',
];

export const contentCells = [
  {
    title: 'ID',
    displayPriority: 1,
    minWidth: 60,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.companies.detail.index,
        params: {
          companyUuid: result.uuid,
        },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmCompanies],
      text: `#${result.id}`,
    }),
  },
  {
    attributeName: 'commercial_name',
    title: 'Name',
    displayPriority: 1,
    minWidth: 180,
    type: RECORD_TYPES.link,
    linkClass: 'emobg-link-primary emobg-body-2',
    url: result => companyProfileUrl(result.uuid),
  },
  {
    attributeName: 'status',
    title: 'Status',
    displayPriority: 1,
    minWidth: 120,
    type: RECORD_TYPES.component,
    component: BadgeComponent,
    props: ({ status }) => ({
      text: sentenceCase(status),
      color: COMPANY_STATUS_COLORS[status],
    }),
  },
  {
    attributeName: 'city',
    title: 'City',
    displayPriority: 2,
    minWidth: 80,
  },
  {
    attributeName: 'has_dedicated_fleet',
    title: 'Dedicated fleet',
    displayPriority: 2,
    minWidth: 125,
    transformValue: value => (Number(value) ? 'Yes' : 'No'),
  },
  {
    attributeName: 'email',
    title: 'Email',
    displayPriority: 0,
    minWidth: 280,
    type: RECORD_TYPES.component,
    component: EmailTemplate,
    props: result => ({
      result,
      email: result.email,
    }),
  },
  {
    attributeName: 'phone_1',
    title: 'Phone',
    displayPriority: 0,
    minWidth: 160,
    type: RECORD_TYPES.component,
    component: PhoneTemplate,
    props: result => ({
      result,
      phone: result.phone_1,
    }),
  },
];

export const companyListfacets = [
  {
    type: 'refinementList',
    props: {
      title: 'City',
      attributeName: 'city',
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Dedicated fleet',
      attributeName: 'has_dedicated_fleet',
      transformValue: value => (Number(value) ? 'Yes' : 'No'),
    },
  },
];
