var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "CreateCompany",
        attrs: {
          header: { isClosable: true },
          buttons: _vm.modalButtons,
          title: "Create company",
          "data-test-id": "create_company-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.crm.company,
                    scope: _vm.COMPANY_SCOPES.companyCreate,
                    element: "company",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "MuiValidationWrapper",
                  {
                    ref: "CompanyCreateForm",
                    attrs: { id: "CompanyCreateForm" },
                    on: {
                      areAllValid: (valid) => (_vm.isFormValid = valid),
                      submit: _vm.saveCompany,
                    },
                  },
                  [
                    _c("h3", { staticClass: "mb-4" }, [
                      _vm._v(" Company Profile "),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("MuiInputText", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: {
                                isRequired: true,
                              },
                              expression:
                                "{\n            isRequired: true,\n          }",
                            },
                          ],
                          staticClass: "w-100",
                          attrs: {
                            label: "Company name*",
                            placeholder: "Enter name",
                            "data-test-id": "commercial_name",
                            name: "commercial_name",
                          },
                          model: {
                            value: _vm.inputs.commercialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "commercialName", $$v)
                            },
                            expression: "inputs.commercialName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isEmail: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isEmail: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Email*",
                              placeholder: "Enter email",
                              "data-test-id": "email",
                              name: "email",
                            },
                            model: {
                              value: _vm.inputs.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "email", $$v)
                              },
                              expression: "inputs.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("PhoneInputComponent", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidPhone:
                                    _vm.PHONE_VALIDATIONS.isValidPhone,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidPhone: PHONE_VALIDATIONS.isValidPhone,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              label: "Phone number*",
                              placeholder: "Enter phone number",
                              name: "phoneNumber",
                              "data-test-id": "phone_number",
                            },
                            model: {
                              value: _vm.inputs.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "phoneNumber", $$v)
                              },
                              expression: "inputs.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("GoogleLocationSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate.update:place",
                                value: {
                                  isRequired: true,
                                  isValidAddress: _vm.checkValidAddress,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              isValidAddress: checkValidAddress,\n            }",
                                modifiers: { "update:place": true },
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              placeholder: "Enter address",
                              label: "Address*",
                              name: "addressStreet",
                              "data-test-id": "address_street",
                            },
                            on: {
                              "update:place": (place) =>
                                (_vm.inputs.addressStreetObject = place),
                              "create:place": (place) =>
                                (_vm.inputs.addressStreetObject = place),
                            },
                            model: {
                              value: _vm.inputs.addressStreet,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "addressStreet", $$v)
                              },
                              expression: "inputs.addressStreet",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiInputText", {
                            staticClass: "w-100",
                            attrs: {
                              label: "Floor, door, apartment...",
                              placeholder: "Enter address",
                              name: "addressStreet2",
                              "data-test-id": "address_street2",
                            },
                            model: {
                              value: _vm.inputs.addressStreet2,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "addressStreet2", $$v)
                              },
                              expression: "inputs.addressStreet2",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  catchNonExistingTariffCity:
                                    _vm.catchNonExistingTariffCity,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              catchNonExistingTariffCity,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              filters: _vm.getOperatorFilter({
                                index: _vm.ALGOLIA_INDEXES.cities,
                              }),
                              title: (city) => city.name,
                              index: _vm.ALGOLIA_INDEXES.cities,
                              label: "City*",
                              placeholder: "Select",
                              "path-value": "uuid",
                              name: "city",
                            },
                            on: {
                              change: () =>
                                (_vm.hasTariffForCityException = false),
                            },
                            model: {
                              value: _vm.inputs.cityUuid,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "cityUuid", $$v)
                              },
                              expression: "inputs.cityUuid",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6 mb-4" },
                        [
                          _c("MuiAlgoliaSelect", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                },
                                expression:
                                  "{\n              isRequired: true,\n            }",
                              },
                            ],
                            staticClass: "w-100",
                            attrs: {
                              title: (locale) =>
                                `${locale.name} (${locale.code})`,
                              index: _vm.localesIndex,
                              filters: "active: true",
                              label: "Language*",
                              "path-value": "code",
                              placeholder: "Select",
                              name: "localeCode",
                              "data-test-id": "locale-select",
                            },
                            model: {
                              value: _vm.inputs.localeCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "localeCode", $$v)
                              },
                              expression: "inputs.localeCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("h3", { staticClass: "mb-4" }, [
                      _vm._v(" Fiscal Information "),
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { isRequired: true },
                                expression: "{ isRequired: true }",
                              },
                            ],
                            attrs: {
                              label: "Company legal name*",
                              "data-test-id": "fiscal_name",
                              name: "fiscalName",
                              placeholder: "Enter name",
                            },
                            model: {
                              value: _vm.inputs.fiscalName,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "fiscalName", $$v)
                              },
                              expression: "inputs.fiscalName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-6" },
                        [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  catchVATNumberError: _vm.catchVATNumberError,
                                },
                                expression:
                                  "{\n              isRequired: true,\n              catchVATNumberError,\n            }",
                              },
                            ],
                            attrs: {
                              label: "VAT number*",
                              "data-test-id": "fiscal_number",
                              name: "fiscalNumber",
                              placeholder: "Enter code",
                            },
                            model: {
                              value: _vm.inputs.fiscalNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "fiscalNumber", $$v)
                              },
                              expression: "inputs.fiscalNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.get(_vm.activeOperator, "is_italian")
                      ? [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isValidFiscalCode:
                                    _vm.FISCAL_CODE_VALIDATIONS
                                      .isValidCompanyFiscalCode,
                                },
                                expression:
                                  "{\n            isRequired: true,\n            isValidFiscalCode: FISCAL_CODE_VALIDATIONS.isValidCompanyFiscalCode,\n          }",
                              },
                            ],
                            staticClass: "mt-4",
                            attrs: {
                              label: "Fiscal code*",
                              "data-test-id": "fiscal_code",
                              name: "fiscalCode",
                              placeholder: "Enter code",
                            },
                            model: {
                              value: _vm.inputs.fiscalCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "fiscalCode", $$v)
                              },
                              expression: "inputs.fiscalCode",
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-x-small emobg-color-ink-light mt-1",
                            },
                            [
                              _vm._v(
                                " Fiscal code is a unique combination of 16 numbers and letters or 11 numbers "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _vm.isFrenchOperator
                      ? [
                          _c("MuiInputText", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  isRequired: true,
                                  isPattern: {
                                    pattern:
                                      _vm.FRENCH_COMPANY_VALIDATION_PATTERN
                                        .siretNumber,
                                    message: "Invalid SIRET number format",
                                  },
                                },
                                expression:
                                  "{\n            isRequired: true,\n            isPattern: {\n              pattern: FRENCH_COMPANY_VALIDATION_PATTERN.siretNumber,\n              message: 'Invalid SIRET number format',\n            },\n          }",
                              },
                            ],
                            staticClass: "mt-4",
                            attrs: {
                              label: "SIRET number*",
                              "data-test-id": "siret_number",
                              name: "siretNumber",
                              placeholder: "Enter number",
                            },
                            model: {
                              value: _vm.inputs.siretNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.inputs, "siretNumber", $$v)
                              },
                              expression: "inputs.siretNumber",
                            },
                          }),
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-caption-1 emobg-color-ink mt-1 px-1",
                            },
                            [
                              _vm._v(
                                " SIRET number is a unique combination of 14 numbers "
                              ),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }