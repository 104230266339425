<script>
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import CreateCompanyModal from '@domains/CRM/Companies/modals/CreateCompany';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { DELAY } from '@emobg/web-utils';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { PageView } from '@/components';
import { getNoResultsLabel, refreshAlgoliaStore } from '@/utils';
import { attributesToRetrieve, companyListfacets, contentCells } from '../const/companiesContentCells';

export default {
  name: 'CompaniesListView',
  components: {
    CreateCompanyModal,
    MuiAlgoliaList,
    PageView,
  },
  data() {
    return {
      isCreateCompanyModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
    this.restrictSearchableAttributes = this.attributesToRetrieve;
    this.contentCells = contentCells;
    this.companyListfacets = companyListfacets;
  },
  methods: {
    getNoResultsLabel,
    onCreateCompanySuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.companies_list, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView class="CompaniesListView">
    <div class="d-flex no-gutters justify-content-between mb-3">
      <h1>
        Companies
      </h1>
      <div class="d-flex justify-content-end">
        <ui-button
          class="emobg-font-small"
          @clickbutton="isCreateCompanyModalVisible = true"
        >
          Create company
        </ui-button>
      </div>
    </div>
    <MuiAlgoliaList
      ref="companies_list"
      :facets="companyListfacets"
      :filters="getOperatorFilter({ attribute: 'cs_operator_id' })"
      :table-config="contentCells"
      :export-columns="contentCells"
      :query-parameters="{ attributesToRetrieve, restrictSearchableAttributes }"
      :index="ALGOLIA_INDEXES.companies"
      :empty-message="getNoResultsLabel('companies')"
      is-export-enabled
    />
    <CreateCompanyModal
      v-if="isCreateCompanyModalVisible"
      :on-success="onCreateCompanySuccess"
      @closeModal="isCreateCompanyModalVisible = false"
    />
  </PageView>
</template>
